import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Popup, PopupContent, PopupTrigger } from '@atlaskit/popup/experimental';
import { Box, xcss } from '@atlaskit/primitives';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { i18n } from './globalNavigationTranslations';
import { iconByMenuId, MoreIconComponent } from './GlobalNavigationIcons';
import type { MenuId, MoreMenuItemConfig } from './useMenuItems';

type MoreMenuItemProps = {
	moreMenuItems: MoreMenuItemConfig;
	isOpen: boolean;
	toggleMenu: () => void;
	onClick: (menuId: MenuId) => void;
	peekingId?: MenuId;
};

export const MoreMenuItem: FC<MoreMenuItemProps> = ({
	moreMenuItems,
	isOpen,
	toggleMenu,
	onClick,
	peekingId,
}) => {
	if (!moreMenuItems || moreMenuItems?.length <= 0) {
		return null;
	}
	return (
		<Popup isOpen={isOpen}>
			<PopupTrigger>
				{(triggerProps) => (
					<MenuButtonItem {...triggerProps} elemBefore={MoreIconComponent} onClick={toggleMenu}>
						<FormattedMessage {...i18n.more} />
					</MenuButtonItem>
				)}
			</PopupTrigger>

			{/* Adding listitem role as the parent is a list. Not using the `li` element so it doesn't inherit default styles. */}
			<Box xcss={popupContentContainerStyles} role="listitem">
				<PopupContent
					placement="bottom-start"
					// Offset the top margin of the menu section.
					// This will keep an even spacing of menu items between those inside the dropdown and those above,
					// and keep the icons aligned
					offset={[-4, 4]}
					onClose={toggleMenu}
					shouldRenderToParent
					strategy="absolute"
					shouldFitContainer
				>
					{() => {
						return moreMenuItems.map((result) => {
							if (peekingId && result.menuId === peekingId) {
								return null; //if an item is peeking, do not render it in this menu
							}
							if (result.href) {
								return (
									<MenuLinkItem
										href={result.href}
										onClick={() => onClick(result.menuId)}
										elemBefore={iconByMenuId[result.menuId]}
										key={result.menuId}
									>
										<FormattedMessage {...i18n[result.menuId]} />
									</MenuLinkItem>
								);
							}
							return (
								<MenuButtonItem
									onClick={() => onClick(result.menuId)}
									elemBefore={iconByMenuId[result.menuId]}
									key={result.menuId}
								>
									<FormattedMessage {...i18n[result.menuId]} />
								</MenuButtonItem>
							);
						});
					}}
				</PopupContent>
			</Box>
		</Popup>
	);
};

const popupContentContainerStyles = xcss({
	position: 'relative',
	marginInline: 'space.negative.050',
	height: '0',
});
