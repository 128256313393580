import React, { useEffect, useState, useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Stack, Text } from '@atlaskit/primitives';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceSiteAri, IdentityUserAri } from '@atlassian/ari';

import { getAGGClient } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';

import { type GlobalMenuItemConfig, type MoreMenuItemConfig } from '../useMenuItems';
import {
	updateNavigationCustomization,
	PLATFORM_MENU_ID_PREFIX,
} from '../GlobalNavigationComponent';

import { CustomizeDraggableList } from './CustomizeDraggableList';
import type {
	CustomizeSidebarDialogMutation as CustomizeSidebarDialogMutationData,
	CustomizeSidebarDialogMutationVariables,
	SettingsMenuItemInput,
} from './__types__/CustomizeSidebarDialogMutation';
import { CustomizeSidebarDialogErrorFlag } from './CustomizeSidebarDialogErrorFlag';

export const SEPARATOR_ID = 'separator';

export type CustomizableListItem = {
	id: string;
};

type CustomizeSidebarDialogProps = {
	globalMenuItems: GlobalMenuItemConfig;
	moreMenuItems: MoreMenuItemConfig;
	onClose: () => void;
};

function convertMenuItemsToListItems(
	globalMenuItems: GlobalMenuItemConfig,
	moreMenuItems: MoreMenuItemConfig,
): CustomizableListItem[] {
	return [
		...globalMenuItems.filter((item) => item.isCustomizable).map(({ menuId }) => ({ id: menuId })),
		{ id: SEPARATOR_ID },
		...moreMenuItems.filter((item) => item.isCustomizable).map(({ menuId }) => ({ id: menuId })),
	];
}

export const CustomizeSidebarDialog = ({
	globalMenuItems,
	moreMenuItems,
	onClose,
}: CustomizeSidebarDialogProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [listItems, setItems] = useState<CustomizableListItem[]>(
		convertMenuItemsToListItems(globalMenuItems, moreMenuItems),
	);

	const onReorderItem = useCallback(
		(startIndex: number, finishIndex: number) =>
			setItems((value) => reorder({ list: value ?? [], startIndex, finishIndex })),
		[],
	);

	const { cloudId, userId } = useSessionData();
	const variables = {
		entityAri: ConfluenceSiteAri.create({ siteId: cloudId }).toString(),
		ownerAri: userId ? IdentityUserAri.create({ userId }).toString() : '',
	};
	const [saveCustomizations] = useMutation<
		CustomizeSidebarDialogMutationData,
		CustomizeSidebarDialogMutationVariables
	>(CustomizeSidebarDialogMutation, {
		client: getAGGClient(),
		context: {
			headers: {
				'X-Atl-Account-Id': userId,
			},
		},
		update: (cache, { data }) => {
			updateNavigationCustomization(
				cache,
				variables,
				data?.settings_updateNavigationCustomisation?.sidebar?.nodes || [],
			);
		},
	});

	const [error, setError] = useState<Error | undefined>();
	const onSave = () => {
		const menuNodes = getCustomizations(listItems);

		saveCustomizations({
			variables: {
				...variables,
				nodes: menuNodes,
			},
			optimisticResponse: {
				settings_updateNavigationCustomisation: {
					sidebar: {
						nodes: menuNodes.map((node) => ({
							...node,
							__typename: 'SettingsMenuItem',
						})),
						__typename: 'SettingsMenuItemConnection',
					},
					__typename: 'SettingsNavigationCustomisation',
				},
			},
		})
			.then(() => {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'updated',
						actionSubject: 'sidebarNavigationItems',
						source: 'customiseSidebarModal',
						attributes: {
							changedFrom: 'customiseSidebarModal',
							namespaces: 'customiseSidebarModal',
							originalNavItems: getCustomizations(
								convertMenuItemsToListItems(globalMenuItems, moreMenuItems),
							),
							updatedNavItems: menuNodes,
						},
					},
				}).fire();
			})
			.catch((e) => {
				setError(e);
			});

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'confirm',
				source: 'customiseSidebarModal',
				attributes: {
					namespaces: 'customiseSidebarModal',
				},
			},
		}).fire();

		onClose();
	};

	const onCancel = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'cancel',
				source: 'customiseSidebarModal',
				attributes: {
					namespaces: 'customiseSidebarModal',
				},
			},
		}).fire();

		onClose();
	};

	const screenEventFired = useRef<boolean>(false);
	useEffect(() => {
		if (!screenEventFired.current) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'customiseSidebarModal',
					attributes: {
						namespaces: 'customiseSidebarModal',
						navItems: getCustomizations(listItems),
					},
				},
			}).fire();
			screenEventFired.current = true;
		}
	}, [createAnalyticsEvent, listItems]);

	return (
		<>
			<ModalTransition>
				<Modal shouldCloseOnEscapePress shouldCloseOnOverlayClick>
					<ModalHeader>
						<Heading size="medium">{intl.formatMessage(i18n.customizeSidebarTitle)}</Heading>
					</ModalHeader>
					<ModalBody>
						<Stack space="space.150">
							<Text as="p" color="color.text.subtlest">
								{intl.formatMessage(i18n.customizeSidebarSubtitle)}
							</Text>
							<CustomizeDraggableList listItems={listItems} onReorderItem={onReorderItem} />
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button onClick={onCancel}>{intl.formatMessage(i18n.customizeSidebarCancel)}</Button>
						<Button appearance="primary" type="submit" onClick={onSave}>
							{intl.formatMessage(i18n.customizeSidebarSave)}
						</Button>
					</ModalFooter>
				</Modal>
			</ModalTransition>
			{error && (
				<ErrorDisplay error={error}>
					<CustomizeSidebarDialogErrorFlag />
				</ErrorDisplay>
			)}
		</>
	);
};

const getCustomizations = (listItems: CustomizableListItem[]) => {
	const nodes: SettingsMenuItemInput[] = [];

	let visible = true;
	listItems.forEach(({ id }) => {
		if (id === SEPARATOR_ID) {
			visible = false;
		} else {
			nodes.push({
				menuId: `${PLATFORM_MENU_ID_PREFIX}${id}`,
				visible,
			});
		}
	});

	return nodes;
};

const CustomizeSidebarDialogMutation = gql`
	mutation CustomizeSidebarDialogMutation(
		$entityAri: ID!
		$ownerAri: ID!
		$nodes: [SettingsMenuItemInput]!
	) {
		settings_updateNavigationCustomisation(
			input: { entityAri: $entityAri, ownerAri: $ownerAri, sidebar: $nodes }
		) {
			sidebar {
				nodes {
					menuId
					visible
					__typename
				}
				__typename
			}
			__typename
		}
	}
`;

const i18n = defineMessages({
	customizeSidebarTitle: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.title',
		defaultMessage: 'Customize sidebar',
		description: 'Title for the customize sidebar dialog',
	},
	customizeSidebarSubtitle: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.subtitle',
		defaultMessage:
			'Show, hide, and reorder navigation items in your left sidebar. Only you’ll see these changes.',
		description: 'Subtitle for the customize sidebar dialog',
	},
	customizeSidebarCancel: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button label for the customize sidebar dialog',
	},
	customizeSidebarSave: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.save',
		defaultMessage: 'Save',
		description: 'Save button label for the customize sidebar dialog',
	},
});
