import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import type { DataProxy } from 'apollo-cache';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { ConfluenceSiteAri, IdentityUserAri } from '@atlassian/ari';

import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { useRouteActions, useRouteDataRef, useTransitionId } from '@confluence/route-manager';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { getAGGClient } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

import { useMenuItems, type MenuId } from './useMenuItems';
import { GlobalItemLoadables } from './GlobalItems/GlobalItemLoadables';
import { MoreMenuItem } from './MoreMenuItem';
import { useFlyoutActions } from './FlyoutStore';
import type {
	GlobalNavigationComponentQuery as GlobalNavigationComponentQueryData,
	GlobalNavigationComponentQueryVariables,
	GlobalNavigationComponentQuery_settings_navigationCustomisation_sidebar_nodes as SettingsMenuItem,
} from './__types__/GlobalNavigationComponentQuery';
import { CustomizeSidebarDialog } from './CustomizeSidebar/CustomizeSidebarDialog';

export const PLATFORM_MENU_ID_PREFIX = 'confluence.sidebar.';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireMoreMenuClickedAnalytics,
);

export const GlobalNavigationComponent = () => {
	const { accessStatus, cloudId, userId } = useSessionData();
	const [peekingId, setPeekingId] = useState<MenuId | undefined>(undefined);
	const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
	const routeDataRef = useRouteDataRef();
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const { openFlyout, closeFlyout } = useFlyoutActions();
	const transitionId = useTransitionId();

	const [isCustomizeSidebarDialogVisible, setIsCustomizeSidebarDialogVisible] =
		useState<boolean>(false);

	useEffect(() => {
		if (transitionId && transitionId > 0) {
			//on any transition close open flyout. On initial Load transitionId = 0
			closeFlyout();
		}
	}, [closeFlyout, transitionId]);

	const moreMenuOnClickCallback = useCallback(
		(menuId: MenuId) => {
			setIsMoreMenuOpen(!isMoreMenuOpen);

			// TODO: it would be great if we could refactor the following menu-specific logic into the respective menu components
			if (menuId === 'customize') {
				setIsCustomizeSidebarDialogVisible(true);
				void fireClickAnalytics('customiseSidebar');
			} else {
				void fireClickAnalytics(menuId);
			}

			if (menuId === 'recent' || menuId === 'starred' || menuId === 'spaces') {
				openFlyout(menuId);
				setPeekingId(menuId);
			}

			if (menuId === 'apps') {
				push('/wiki/marketplace/discover');
			}
		},
		[fireClickAnalytics, isMoreMenuOpen, openFlyout, push],
	);

	const { data, error } = useQuery<
		GlobalNavigationComponentQueryData,
		GlobalNavigationComponentQueryVariables
	>(GlobalNavigationComponentQuery, {
		client: getAGGClient(),
		variables: {
			entityAri: ConfluenceSiteAri.create({ siteId: cloudId }).toString(),
			ownerAri: userId ? IdentityUserAri.create({ userId }).toString() : '',
		},
		skip: !userId || !fg('confluence_nav_4_beta'),
	});

	const platformCustomizations = useMemo(() => {
		if (data?.settings_navigationCustomisation?.sidebar?.nodes) {
			return data.settings_navigationCustomisation.sidebar.nodes.map((node) => ({
				menuId: node.menuId?.replace(PLATFORM_MENU_ID_PREFIX, '') as MenuId,
				visible: Boolean(node.visible),
			}));
		}
		return [];
	}, [data]);

	const isPermitted = !(
		accessStatus === AccessStatus.ANONYMOUS_ACCESS ||
		accessStatus === AccessStatus.UNLICENSED_AUTHENTICATED_ACCESS
	);
	const { globalMenuItems, moreMenuItems } = useMenuItems(isPermitted, platformCustomizations);

	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'sideNavigation-globalNavigation',
			}}
		>
			{globalMenuItems.map((result) => {
				const Component = GlobalItemLoadables[result.menuId];
				return (
					Component && (
						<Component
							key={result.menuId}
							setIsCustomizeSidebarDialogVisible={setIsCustomizeSidebarDialogVisible}
						/>
					)
				);
			})}
			{moreMenuItems.map((result) => {
				const Component = GlobalItemLoadables[result.menuId];
				return (
					Component && (
						<Component
							key={result.menuId}
							isHidden
							peekingId={peekingId}
							setPeekingId={setPeekingId}
							setIsCustomizeSidebarDialogVisible={setIsCustomizeSidebarDialogVisible}
						/>
					)
				);
			})}
			<MoreMenuItem
				moreMenuItems={moreMenuItems}
				isOpen={isMoreMenuOpen}
				onClick={moreMenuOnClickCallback}
				toggleMenu={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
				peekingId={peekingId}
			/>
			{error && <ErrorDisplay error={error} />}
			{isCustomizeSidebarDialogVisible && fg('confluence_nav_4_beta') && (
				<CustomizeSidebarDialog
					globalMenuItems={globalMenuItems}
					moreMenuItems={moreMenuItems}
					onClose={() => setIsCustomizeSidebarDialogVisible(false)}
				/>
			)}
		</ErrorBoundary>
	);
};

export const updateNavigationCustomization = (
	cache: DataProxy,
	variables: GlobalNavigationComponentQueryVariables,
	nodes: SettingsMenuItem[],
) => {
	cache.writeQuery({
		query: GlobalNavigationComponentQuery,
		variables,
		data: {
			settings_navigationCustomisation: {
				__typename: 'NavigationCustomisation',
				sidebar: {
					__typename: 'SidebarCustomisation',
					nodes,
				},
			},
		},
	});
};

const GlobalNavigationComponentQuery = gql`
	query GlobalNavigationComponentQuery($entityAri: ID!, $ownerAri: ID!) {
		settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) {
			sidebar {
				nodes {
					menuId
					visible
				}
			}
		}
	}
`;
